<template>
  <div class="panoramic-container" v-if="!showModel">
    <div class="comback-box" @click="overallViewComback">返回</div>
    <iframe
      :src="panoramicUrl"
      scrolling="yes"
      frameborder="0"
      style="height: 100vh; width: 100%"
    >
    </iframe>
  </div>
 <div class="caseShare-container" v-if="showModel">
    <article class="case-details" v-if="loadingCase">
      <!-- 工程详情 -->
      <div class="building-item">
        <div class="building-img">
          <img
            :src="
              oneCase.headImgUrl ? `${imgUrl}/${oneCase.headImgUrl}` : morenImg
            "
            alt=""
             @click="loadPanoramic"
          />
        </div>
        <div class="building-info">
          <div class="info1">
            <div class="title-text">{{ detail.title }}</div>
            <!-- limitWords(detail.title, 20) -->
          </div>
          <div class="info2">
            <div class="room-describle">
              {{ detail.typeName }} | {{ detail.area }}m² |
              {{ detail.styleName }}
            </div>
            <div class="building-stage">
              <svg
                style="width: 12px; height: 12px; line-height: 12px"
                fill="#289FFF"
                t="1648619964035"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2453"
                width="200"
                height="200"
              >
                <path
                  d="M511.914 63.99c-247.012 0-447.925 200.912-447.925 447.924s200.913 447.925 447.925 447.925 447.925-200.913 447.925-447.925S758.926 63.989 511.914 63.989z m0 831.687c-211.577 0-383.763-172.186-383.763-383.763 0-211.577 172.014-383.763 383.763-383.763s383.763 172.014 383.763 383.763-172.186 383.763-383.763 383.763z"
                  fill=""
                  p-id="2454"
                ></path>
                <path
                  d="M672.06 511.914H512.085v-223.79c0-17.718-14.277-32.167-31.995-32.167-17.717 0-31.994 14.45-31.994 32.167V544.08c0 17.717 14.277 31.994 31.994 31.994H672.06c17.718 0 32.167-14.277 32.167-31.994-0.172-17.89-14.621-32.167-32.167-32.167z"
                  fill=""
                  p-id="2455"
                ></path>
              </svg>
              {{ detail.stageName }}
            </div>
          </div>
          <div class="info3">
            <div class="location-text">
              <svg
                t="1648621241158"
                fill="#BFBFBF"
                style="width: 12px; height: 12px"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3268"
                width="200"
                height="200"
              >
                <path
                  d="M533.162667 846.813867a23.825067 23.825067 0 0 1-32.768 0C326.587733 682.0864 238.933333 550.570667 238.933333 448.477867a277.845333 277.845333 0 1 1 555.690667 0c0 102.126933-87.688533 233.608533-261.461333 398.336z m-246.613334-398.336c0 82.090667 76.288 199.2704 230.229334 348.091733 153.941333-148.821333 230.1952-266.001067 230.1952-348.0576a230.1952 230.1952 0 1 0-460.424534 0z m230.229334 87.3472a119.057067 119.057067 0 1 1 0-238.148267 119.057067 119.057067 0 0 1 0 238.148267z m0-47.650134a71.441067 71.441067 0 1 0 0-142.882133 71.441067 71.441067 0 0 0 0 142.882133z"
                  fill="#999999"
                  p-id="3269"
                ></path>
              </svg>
              {{ detail.region }} {{ detail.apartmentName }}
            </div>
          </div>
        </div>
      </div>
      <!-- 名片 -->
      <van-sticky>
        <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
        <div
          v-else
          class="case-namecard"
          style="background-color: #fff"
          @click="handleViewUserCard"
        >
          <div class="img-name">
            <!-- @click="loadUserCard" -->
            <img
              :src="
                oneCase.userCard.headImgUrl
                  ? `${imgUrl}/${oneCase.userCard.headImgUrl}`
                  : morenHeadImg
              "
              alt=""
            />
            <div class="creater-info">
              <p>{{ detail.userCard.name }}</p>
              <p>{{ detail.userCard.merchantName }}</p>
            </div>
          </div>
          <div class="create-describe">
            <span>{{
              isFold
                ? limitWords(detail.userCard.selfIntro, 40)
                : detail.userCard.selfIntro
            }}</span>
            <span
              style="color: #999"
              @click="handleIsFold"
              v-if="detail.userCard.selfIntro.length > 40"
              >{{ isFold ? "展开" : "&lt;&lt;折叠" }}</span
            >
          </div>
        </div>
      </van-sticky>
      <!-- 施工阶段 -->
      <div class="construction-stage">
        <div class="construction-stage-line"></div>
        <div class="construction-stage-box">
          <div class="construction-stage-name">
            <span>施工阶段</span>
            <span @click="showConsulting = true">预约参观工地</span>
          </div>
          <van-steps
            :active="tempStageIndex"
            active-icon="success"
            active-color="#38f"
          >
            <van-step
              v-for="(item, index) in detail.siteContentList"
              :key="index"
              @click="changeSteps(index, item)"
            ></van-step>
          </van-steps>
          <div class="steps-name">
            <div
              class="steps-name-text"
              v-for="(item, index) in detail.siteContentList"
              :key="index"
              :style="{ color: stageIndex == index ? '#333' : '#999' }"
            >
              {{ item.stageName }}
            </div>
          </div>
        </div>
        <div class="construction-stage-line"></div>
      </div>
      <!-- 施工进度\设计师\样板图片 -->
      <div class="construction-progress">
        <div
          class="progress-item-box"
          v-for="(item, index) in showConstructionProgress"
          :key="index"
        >
          <div class="header-img">
            <img
              :src="
                item.headPortraitUrl
                  ? `${imgUrl}/${item.headPortraitUrl}`
                  : morenHeadImg
              "
              alt=""
            />
          </div>
          <div class="progress-info-box">
            <div class="progress-info1">
              <div class="progress-info1-name">{{ item.userName }}</div>
              <div class="progress-info1-steps">{{ item.stageName }}</div>
            </div>
            <div class="progress-info2">{{ item.progress }}</div>
            <div class="progress-info3">
              {{ item.content }}
            </div>
            <div class="progress-info4">
              <img
                :src="`${imgUrl}/${img.url}`"
                alt=""
                v-for="(img, imgIndex) in item.fileDetailsList"
                :key="imgIndex"
                @click="myImagePreview(imgIndex, item.fileDetailsList)"
              />
            </div>
            <div class="progress-info5">{{ item.updateDate }}</div>
          </div>
        </div>
      </div>
    </article>
    <footer class="huoke-footer">
      <div class="article_list_buttom2">
        <van-tabbar v-model="active" active-color="#646566" @change="onChange">
          <van-tabbar-item>
            <icon-svg iconClass="iconsmartphone1" class="zixun" />
            <span>电话联系</span>
          </van-tabbar-item>
          <van-tabbar-item>
            <icon-svg iconClass="iconmessage-square" class="zixun" />
            <span>我要预约</span>
          </van-tabbar-item>
        </van-tabbar>
      </div>
    </footer>
    <!-- 咨询 -->
    <div class="consulting">
      <van-overlay :show="showConsulting" @click="showConsulting = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p class="title">我要预约</p>
            <van-form ref="addInfoForm" error-message-align="right">
              <div class="info">
                <van-field
                  v-model="addInfo.username"
                  label="姓名"
                  :rules="[{ required: true, message: '请输入名称' }]"
                />
                <van-field
                  v-model="addInfo.phone"
                  label="手机号"
                  type="tel"
                  :rules="[
                    {
                      required: true,
                      pattern: /^1[3|4|5|7|8]\d{9}$/,
                      message: '无效手机号',
                    },
                  ]"
                />
                <van-field
                  v-model="addInfo.content"
                  label="留言"
                  type="textarea"
                  :rules="[{ required: true, message: '请输入留言' }]"
                />
              </div>
            </van-form>
            <van-grid clickable :column-num="2">
              <van-grid-item text="返回" @click="handlecomback" />
              <van-grid-item text="保存" @click="handleAddInfo" />
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, onUnmounted, ref } from "vue";
import { Dialog, Toast,ImagePreview } from "vant";
import { useRoute, useRouter } from "vue-router";
import { createCaseConsult } from "@/api/case";
import { findBuildSiteByIdApp,addNum } from "@/api/buildSite";
// addNum
import { addClueBehaviorInfo } from "@/api/user/clue";
import { useWxAuthorization } from "@/hooks/wxAuthorization";
// import transformHtml from "@/components/transformHTML.vue";
import $ from "jquery";
import { initWechatShare } from "@/hooks/initWechatShare";
import { addClue } from "@/api/user/clue";
export default {
  //   components: {
  // transformHtml,
  //   },
    components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    let internal = null;
    let seconds = 0;
    const route = useRoute();
    const router = useRouter();
    let addInfoForm = ref(null);
    const merchantId = route.params.merchantId;
    const recommendClueId = route.params.recommendClueId? route.params.recommendClueId:'';
    const state = reactive({
      showSkeleton: true,
      showModel: true,
      panoramicUrl: "",
      morenImg: require("../../assets/images/buildSite-default.png"),
      morenHeadImg: require("../../assets/icons/Vector.png"),
      active: null,
      showConsulting: false,
      loading: false,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      loadingCase: false,
      showShare: false,
      buildId: route.params.id, 
      consultId: null,
      openId: null,
      oneCase: {},
      //====新增===start====
      detail: {},
      totalStageIndex: 0, //总的工程阶段
      stageIndex: 0, //自己选择的工程阶段
      tempStageIndex: 0,
      curretnStageIndex: 0, //目前工程阶段
      showConstructionProgress: [], //将要显示的施工进程详情
      //=======end==========
      icon: {
        inactive: require("../../assets/icons/smartphone.png"),
        active: require("../../assets/icons/smartphone.png"),
        mycaseN: require("../../assets/icons/wechat-chat.png"),
        mycaseC: require("../../assets/icons/wechat-chat.png"),
        consult: require("../../assets/icons/message-square.png"),
      },
      addInfo: {
        username: "",
        phone: "",
        content: "",
        busiId: route.params.id,
      },
      synopsisStr: "",
      foldSynopsisStr: "",
      isFold: true,
      clueId: "",
    });
    console.log("===============>",route.params);
    //进行电话和咨询操作
    const onChange = (index) => {
      if (index === 0) {
        handleCall();
        state.active = null;
      }
      if (index === 1) {
        state.showConsulting = true;
      }
    };
    //返回按钮
    const handlecomback = () => {
      state.showConsulting = false;
      state.active = null;
    //   state.addInfo.content = "";
    };
    //保存按钮
    const handleAddInfo = () => {
      state.active = null;
      let param = {
        ...state.addInfo,
      };
      addInfoForm.value.validate().then(() => {
        createCaseConsult(param).then((data) => {
          if (data) {
            state.consultId = data.id;
            // Dialog.alert({ title: "成功", message: "您的咨询已创建" });
            Toast.success("您的预约已创建");
            countUserAction({
              clueId: state.clueId,
              openId: state.openId,
              type: 5,
            });
            state.showConsulting = false;
          }
        });
      });
    };
    //初始化数据
    const loadCaseDetails = async () => {
      let result = await findBuildSiteByIdApp(state.buildId);
      state.oneCase = result;
      state.synopsisStr = result.userCard.selfIntro
        ? result.userCard.selfIntro
        : "我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！";
      state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
      state.panoramicUrl = result.panoramicUrl;
      state.showSkeleton = false;
      console.log("案例详情", result);
      state.detail = result;
      state.loadingCase = true;
      //控制名片会不会有展开和缩进
      state.detail.userCard.selfIntro.length > 40
        ? (state.isFold = true)
        : (state.isFold = false);
      //控制施工阶段有几个
      state.totalStageIndex = state.detail.siteContentList.length ?? 0;
      //控制施工阶段当前阶段
      state.curretnStageIndex = state.detail.stageId ?? 0;
      state.stageIndex = state.detail.stageId ?? 0;
      //控制初始化要显示的施工阶段进度详情
      state.detail.siteContentList.forEach((element, index) => {
        if (element.stageId == state.curretnStageIndex) {
          //主要内容和控制步骤点颜色是这步(因为不需要点击切换详情 所以不需要tempStageIndex)
          state.tempStageIndex = index;
          return (state.showConstructionProgress = element.contentList);
        }
        // console.log(state.stageIndex, "111111");
      });
    };
    //查看步骤中的图片
    const myImagePreview = (index, list) => {
      var imgList = [];
      list.forEach((element) => {
        var tempImg = `${state.imgUrl}/${element.url}`;
        imgList.push(tempImg);
      });
      ImagePreview({
        images: imgList,
        closeable: true,
        startPosition: index,
      });
    };
     const changeSteps = (e, item) => {
      if (item.stageId <= state.curretnStageIndex) {
        state.stageIndex = item.stageId;
        state.tempStageIndex = e;
        state.showConstructionProgress = item.contentList;
      }
      console.log(e, state.curretnStageIndex, state.stageIndex);
    };
    //展开折叠
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    //授权
    const useWxAuth = async () => {
    //   console.log(route.params,"路由")
      let data = await useWxAuthorization();
      if (data.openId) {
        state.openId = data.openId;
      } else {
        state.openId = route.query.openId;
      }

      if (state.openId) {
        await loadCaseDetails();
        await handleFirstAddClue();
        let temp = location.href.split("?")[0];
        if (recommendClueId) {
          temp = temp.replace(recommendClueId, state.clueId);
        } else {
          temp = `${temp}/${state.clueId}`;
        }
        countUserAction({
          clueId: state.clueId,
          openId: state.openId,
          type: 1,
        });
        let shareImg;
        if (state.oneCase.headImgUrl) {
          shareImg = `${process.env.VUE_APP_WATCH_FILE_API}/${state.oneCase.headImgUrl}`;
        } else {
          shareImg = "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
        }

        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.postMessage({
              data: {
                title:`${state.oneCase.title}已进入${state.oneCase.stageName??""}，围观了解施工注意事项！`,
                img: shareImg,
                openId: state.openId,
                clueId: state.clueId,
                shareUrl: temp,
                type: 4,
              },
            });
               countUserAction({
                clueId: state.clueId,
                openId: state.openId,
                type: 4,
                });
          } else {
            let title = `${state.oneCase.title}已进入${state.oneCase.stageName??""}，围观了解施工注意事项！`;
            let desc = state.oneCase.title;
            let appPhone;
            if (localStorage.getItem("appPhone")) {
              appPhone = localStorage.getItem("appPhone");
            } else {
              appPhone = route.query.appPhone;
            }
            let url = `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=${temp}?appPhone=${appPhone}`;
            let imgUrl = shareImg;
            initWechatShare({
              title,
              desc,
              url,
              imgUrl,
              openId: state.openId,
              clueId: state.clueId,
              type: 4,
            });
          }
        });
      }
    };
    //打电话
    const handleCall = () => {
      if (state.oneCase.userCard.contactNum) {
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${state.oneCase.userCard.contactNum}`);
        a.click();
        countUserAction({
          clueId: state.clueId,
          openId: state.openId,
          type: 2,
        });
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    //线索
    const handleFirstAddClue = async () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      let param = {
        shareId: state.buildId,
        clueSource: 7,
        browseSecond: 0,
        beSharePhone: miniProgramPhone,
        openId: state.openId,
        title: state.oneCase.title,
        createId: state.oneCase.loginUserId,
        consultId: state.consultId,
        clueShareSource: route.params.clueShareSource,
        recommend_Clue_Id: recommendClueId,
        merchantId,
      };
      let data = await addClue(param);
      state.clueId = data;
      return data;
    };
    //查看卡片信息
    const handleViewUserCard = () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      router.push({
        path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
      });
    };
    //添加线索
    const handleAddClue = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.buildId,
          clueSource: 7,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          title: state.oneCase.title,
          createId: state.oneCase.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id: recommendClueId,
          merchantId,
        };
        $.ajax({
          async: false,
          url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          type: "post",
          contentType: "application/json;charset=UTF-8",
          data: JSON.stringify(param),
        });
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleWindowHide = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          clueId: state.clueId,
          browseSecond: seconds,
          consultId: state.consultId,
          shareId: state.buildId,
          clueSource: 7,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          title: state.oneCase.title,
          createId: state.oneCase.loginUserId,
          clueShareSource: route.params.clueShareSource,
          recommend_Clue_Id: recommendClueId,
          merchantId,
        };
        let blob = new Blob([JSON.stringify(param)], {
          type: "application/json",
        });
        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          blob
        );
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        handleAddClue();
        clearInterval(internal);
        seconds = 0;
      }
    };
    const countUserAction = async ({ clueId, openId, type }) => {
      /**
       * type:
       *  1查看
       *  2拨打
       *  3保存
       *  4转发
       *  5提交咨询
       *  6点赞
       *  7收藏
       *  8留言
       */

      if (clueId) {
        await addClueBehaviorInfo({ clueId, openId, type });
      }
    };
    onMounted(() => {
        addNum(route.params.id, "views");
      // loadHotArticle();
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      document.addEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.addEventListener("pagehide", handleWindowHide, false);
    });
    onUnmounted(() => {
      clearInterval(internal);
      handleAddClue();
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.removeEventListener("pagehide", handleWindowHide, false);
    });
    //全景图返回
    const overallViewComback = () => {
      state.showModel = !state.showModel;
    };
     //全景漫游
    const loadPanoramic = () => {
        // console.log("quanjing",state.panoramicUrl);
        if(state.panoramicUrl) state.showModel = !state.showModel;
      }
    useWxAuth();
    // loadCaseDetails();
    const limitWords = (e, num) => {
      if (e.length > num) {
        return e.substring(0, num) + "...";
      } else {
        return e;
      }
    };
    return {
      ...toRefs(state),
      overallViewComback,
      handleIsFold,
      addInfoForm,
      onChange,
      handlecomback,
      handleAddInfo,
      handleCall,
      limitWords,
      handleViewUserCard,
      myImagePreview,
      changeSteps,
      loadPanoramic,
    };
  },
};
</script>
<style lang="scss">
.panoramic-container {
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  .comback-box {
    position: absolute;
    height: 36px;
    width: 60px;
    line-height: 36px;
    text-align: center;
    left: 0;
    top: 100px;
    color: white;
    font-size: 14px;
    background: rgba(46, 46, 46, 0.5);
    border-radius: 0 20px 20px 0;
    opacity: 0.5;
  }
}
.caseShare-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .case-details {
    flex: 1;
    overflow-x: hidden;
    color: rgba(0, 0, 0, 0.56);
    font-size: 14px;
    .card-box-cls {
      background-color: #fff;
      padding: 5px 16px 16px 16px;
    }
    .card-box {
      // margin: 5px 16px 16px 16px;
      padding: 12px;
      border-radius: 8px;
      text-align: left;
      background-color: #fff;
      box-shadow: 0px 0px 15px rgb(229, 229, 230);
      .synopsis {
        margin-top: 5px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.84);
      }
      .card-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .card-left {
          display: flex;
          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }
          .creater-info {
            margin-left: 8px;
            .title {
              font-weight: bold;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.84);
              margin-bottom: 3px;
            }
            .second {
              font-weight: normal;
              font-size: 11px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .card-right {
          .phone {
            width: 80px;
            height: 30px;
            background-color: #289fff;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
          }
        }
      }
    }
    .case-base-info {
      padding: 0 16px;
      border-bottom: 1px solid #f7f7f7;
      .case-namecard {
        //  border: 1px solid #cccccc;
        border-radius: 8px;
        box-shadow: 0 0 6px 6px #f7f7f7;
        .img-name {
          height: 40px;
          padding: 5px 16px;
          img {
            display: block;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: bold;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .create-describe {
          font-size: 12px;
          text-align: left;
          padding: 5px 16px;
        }
      }
      .mycase-box-info {
        flex: 1;
        padding: 5px 0;
        .case-box {
          font-size: 16px;
          text-align: left;
          .cover-img {
            height: 160px;
            position: relative;
            .house-tour {
              position: absolute;
              top: 50%;
              left: 50%;
              color: #fff;
              transform: translate(-50%, -50%);
              background: rgba(31, 30, 30, 0.5);
              opacity: 0.8;
              height: 20px;
              border-radius: 20px;
              font-size: 14px;
              padding: 8px 30px;
            }
            > img {
              display: block;
              border-radius: 4px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .case-info {
            //  height: auto;
            position: relative;
            display: flex;
            width: 343px;
            justify-content: space-between;
            .title {
              //  float: left;
              position: relative;
              width: 330px;
              > p {
                margin: 0;
                padding: 0;
              }
              > p:nth-child(1) {
                font-weight: 700;
                margin-top: 8px;
                color: #333333;
              }
              > p:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                margin: 4px 0;
                color: #666666;
              }
            }
            .share-del {
              top: 10px;
              position: relative;
              .share {
                height: 20px;
                width: 20px;
                background: url("../../assets/icons/share-3.png") no-repeat;
              }
              .del {
                height: 20px;
                margin-top: 15px;
                width: 20px;
                background: url("../../assets/icons/trash-2.png") no-repeat;
              }
            }
          }
        }
      }
    }
    .case-design {
      padding: 5px 16px;
      padding-bottom: 0;
      text-align: left;
      p {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
      }
      .decribe-text {
        color: #666;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .case-content {
        width: 100%;
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        background-color: #ffffff;
        .case-content-box {
          // height: 300px;
          // background-color: deeppink;
          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
          }
          border-bottom: 1px solid #f1f3f6;
        }
      }
      .case-design-details {
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        > span {
          color: #666;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .case-content {
      width: 100%;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .case-content-box {
        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 400;
        }
        border-bottom: 1px solid #f1f3f6;
      }
    }
    // 工地单个卡片
    .building-item {
      padding: 16px 16px 2px 16px;
      .building-img {
        position: relative;
        height: 160px;
        border-radius: 4px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .building-info {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        > div {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
        }
        .info1 {
          font-size: 14px;
          display: flex;
          flex-direction: row;
          .title-text {
            flex: 1;
            text-align: start;
          }
        }
        .info2 {
          display: flex;
          flex-direction: row;
          > div {
            flex: 1;
            font-size: 12px;
            text-align: start;
          }
          .room-describle {
            color: #666;
          }
          .building-stage {
            color: #289fff;
          }
        }
        .info3 {
          display: flex;
          flex-direction: row;
          .location-text {
            text-align: start;
            font-size: 12px;
            color: #bfbfbf;
            flex: 1;
          }
        }

        .info4 {
          padding-top: 16px;
          font-size: 12px;
          color: #999;
          justify-content: space-evenly;
        }
      }
    }

    //名片
    .van-sticky {
      margin: 20px 0;
      .case-namecard {
        background-color: #ffff;
        margin: 0 16px;
        margin-bottom: 20px;
        padding: 0 16px;
        box-shadow: 0px 5px 15px rgb(229, 229, 230);
        border-radius: 8px;
        .img-name {
          height: 40px;
          padding: 5px 0;
          img {
            display: block;
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .create-describe {
          font-size: 12px;
          text-align: left;
          padding: 5px 16px;
        }
      }
    }
    // 施工阶段进度条样式
    .construction-stage {
      // background-color: #e5e5e5;
      // padding: 8px 0;
      .construction-stage-line {
        background-color: #e5e5e5;
        height: 8px;
        width: 100%;
      }

      .construction-stage-box {
        background-color: #ffff;
        height: 131px;
        padding: 16px;
        z-index: 66;
        .construction-stage-name {
          display: flex;
          justify-content: space-between;
          > span:nth-child(1) {
            text-align: left;
            font-weight: 600;
            color: #333;
          }
          > span:nth-child(2) {
            text-align: right;
            padding: 0 10px;
            line-height: 22px;
            color: #fff;
            height: 22px;
            border-radius: 4px;
            background-color: #41cb82;
          }
        }

        .van-step__circle {
          width: 11px;
          height: 11px;
        }

        .van-step__line {
          background: #bfbfbf;
        }

        .van-icon-success::before {
          width: 11px;
          height: 11px;
          background-color: #289fff;
          border-radius: 50%;
          color: #289fff;
        }
      }
      .steps-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .steps-name-text {
          display: flex;
          font-size: 11px;
          justify-content: flex-start;
        }
      }
    }

    // 施工进度\设计师\样板图片
    .construction-progress {
      .progress-item-box {
        border-bottom: #f1f3f6 solid 1px;
        padding: 16px 9px 18px 15px;
        display: flex;
        flex-direction: row;
        .header-img {
          width: 48px;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          padding-right: 5px;
          > img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .progress-info-box {
          text-align: start;
          .progress-info1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .progress-info1-name {
              color: #333;
              font-weight: 600;
            }
            .progress-info1-steps {
              color: #666;
              text-align: end;
            }
          }

          .progress-info4 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            > img {
              width: 90px;
              height: 90px;
              object-fit: cover;
              margin: 2px 2px;
            }
          }

          .progress-info2,
          .progress-info5 {
            color: #999;
            font-size: 12px;
          }
          .progress-info3 {
            color: #333;
          }
        }
      }
    }
  }
  .huoke-footer {
    position: relative;
    height: 50px;
    .add_article {
      height: 36px;
      width: 52px;
      color: #fff;
      line-height: 36px;
      border-radius: 8px;
      font-size: 28px;
      position: fixed;
      z-index: 2;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
      background-color: #ff6e01;
    }
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .zixun {
        display: block;
        margin-bottom: 3px;
        font-size: 22px;
      }
    }
  }
  //遮罩层
  .consulting {
    font-size: 14px;
    font-weight: bold;
    color: #0000;

    .van-field__label {
      width: 44px;
    }

    .van-field__control {
      font-weight: 500;
      width: 194px;
      border: 1px solid #bfbfbf;
      border-radius: 5px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .block {
      width: 303px;
      border-radius: 8px;
      background-color: #fff;
      color: black;

      > div {
        // height: 20px;
        > div:nth-child(1) {
          .van-hairline {
            border-radius: 0 0 0 10px;
            padding: 0;

            height: 46px;

            .van-grid-item__text {
              color: #999999;
            }
          }
        }

        > div:nth-child(2) {
          .van-hairline {
            border-radius: 0 0 10px 0;
            padding: 0;

            .van-grid-item__text {
              color: #333333;
            }
          }

          [class*="van-hairline"]::after {
            border-right: none;
          }
        }
      }

      [class*="van-hairline"]::after {
        border-bottom: none;
      }

      .name-card {
        background-color: #ffff;
        border-bottom: 1px solid #f1f3f6;
        padding: 0 16px;

        p {
          padding: 0;
          margin: 0;
        }

        .name-info {
          display: flex;
          text-align: left;
          font-size: 14px;
          color: #333333;
          padding: 8px 0;
          // justify-content: space-between;
          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }

          .creater-info {
            margin-left: 8px;

            > p:nth-child(1) {
              font-weight: 700;
            }

            > p:nth-child(2) {
              font-size: 12px;
              color: #666666;
            }
          }
        }

        .synopsis {
          text-align: left;
          font-size: 12px;
          color: black;
        }
      }

      .info {
        padding: 0 10px;

        .van-cell::after {
          border: none;
        }

        .van-field__label {
          color: black;
        }

        [class*="van-hairline"]::after {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
